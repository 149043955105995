.version-image-wrapper {
  position: relative;
  width: 100%;
  transition: transform 0.2s ease, margin 0.2s ease;
}

.version-image-wrapper.dragging {
  opacity: 0.5;
  transform: scale(0.95);
  z-index: 1000;
}

.version-image-wrapper.drag-over {
  margin-bottom: 40px;
}

.version-image-wrapper .drop-indicator {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #2196F3;
  box-shadow: 0 0 4px rgba(33, 150, 243, 0.5);
}

.version-image {
  cursor: move;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.version-image:hover {
  transform: translateX(2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.version-image-container {
  padding: 10px;
  min-height: 100px;
}