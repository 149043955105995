.new-design {
    .min-container {
        margin: 0 7vw;

        @media (max-width: 1024px) {
            margin: 0 4vw;
        }
    }

    .fw-600 {
        font-weight: 600;
    }

    .fs-32 {
        @media (max-width: 576px) {
            font-size: 20px !important;
        }
    }

    .new-design-gridTemplate {
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: 20px;

        @media (max-width: 1200px) {
            grid-template-columns: auto auto auto;
        }

        @media (max-width: 768px) {
            grid-template-columns: auto auto;
            gap: 15px;
        }

        @media (max-width: 480px) {
            grid-template-columns: auto;
            justify-content: center;
            gap: 10px;
        }
    }

    .new-design-image {
        max-width: 352px;
        height: 190px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1024px) {
            height: 170px;
        }

        @media (max-width: 768px) {
            height: 150px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .new-design-modal {
        .modal-dialog {
            max-width: 1462px !important;

            .modal-content {
                overflow-y: auto;
                max-height: calc(100vh - 70px);

                .modal-header {
                    position: sticky;
                    top: 0px;
                    z-index: 2;
                    background-color: white;
                }

                .modal-body {
                    .template-image {
                        max-width: 210px;
                        width: 100%;
                        height: 210px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media (max-width: 992px) {
                            height: 180px;
                        }

                        @media (max-width: 576px) {
                            height: 140px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    select {
                        border-radius: 8px;
                        border: 1px solid #b3b3b3;

                        &:focus {
                            box-shadow: none !important;
                        }

                        @media (max-width: 576px) {
                            font-size: 14px !important;
                        }
                    }

                    .template-image-row {
                        display: grid;
                        grid-template-columns: auto auto auto auto auto auto;
                        gap: 20px;

                        @media (max-width: 1250px) {
                            grid-template-columns: auto auto auto auto auto;
                        }

                        @media (max-width: 992px) {
                            grid-template-columns: auto auto auto auto;
                        }

                        @media (max-width: 768px) {
                            grid-template-columns: auto auto auto;
                            gap: 15px;
                        }

                        @media (max-width: 500px) {
                            grid-template-columns: auto auto;
                            justify-content: center;
                            gap: 10px;
                        }
                    }
                }
            }
        }
    }
}

.header_Bigline {
    background-color: #e6e6e6;
    height: 24px;
    width: 1px;
}

.header-true-button {
    box-shadow: 0px 1px 4px 0px #0000003d;
    border: 1px solid #0000001e;
    height: 24px;
    width: 24px;
    border-radius: 4px;

    img {
        width: 20px;
    }
}

.btn-black {
    background-color: black;
    color: white;
    border-radius: 30px;
    border: 1px solid transparent;
    transition: all ease-in-out 0.4s;
    font-size: 14px;
    font-family: "Noto Sans";
    padding: 12px 16px;

    &:hover {
        border: 1px solid black;
        color: black;
        background-color: transparent;
    }

    @media (max-width: 1024px) {
        padding: 8px 14px;
    }

    @media (max-width: 576px) {
        padding: 6px 12px;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.ai-vision-button {
    background: linear-gradient(90deg, #312eff 0%, #7234f3 55.93%, #ff4daf 100%);
    height: 39px;
    border: 0px;

    @media (max-width: 768px) {
        height: 28px;
    }
}

.header-dropdown {
    .btn {
        &:hover {
            color: black !important;
        }
    }

    .dropdown-menu {
        border: 1px solid #8d8d8d26 !important;

        .dropdown-item {
            border: none !important;
        }
    }

    .dropdown-toggle {
        transition: all ease-in-out 0.5s;

        &::after {
            height: 8px;
            width: 8px;
            border-width: 1.5px 1.5px 0 0 !important;
            border-style: solid;
            border-color: black;
            rotate: 135deg;
            margin-top: 6px;

            @media (max-width: 768px) {
                margin-top: 3px;
                margin-left: 8px;
            }

            @media (max-width: 576px) {
                margin-left: 8px !important;
            }
        }
    }

    .show {
        &::after {
            rotate: 317deg;
            margin-top: 10px;
        }
    }
}

.text-size-btn {
    font-size: 16px;
    height: 56px;

    @media (max-width: 768px) {
        font-size: 12px;
        height: 36px;
    }
}

.add-fs-16 {
    font-size: 16px !important;

    @media (max-width: 768px) {
        font-size: 12px !important;
    }
}

.black-gradiant-btn {
    background-image: linear-gradient(90deg, #000000 0%, #5f5e5e 71.45%);
    border-radius: 8px;
    max-width: 360px;
    height: 56px;
    color: white;

    @media (max-width: 768px) {
        height: 36px;
    }

    span {
        @media (max-width: 768px) {
            font-size: 12px;
        }
    }

    &:disabled {
        border: 2px solid #e6e6e6;
        color: #656565;
        background: #f6f6f6;
    }
}

.tabs-search {
    input {
        padding-left: 30px !important;
        height: 40px;
        border-radius: 8px !important;
        border: 1px solid #b3b3b3 !important;
        font-size: 14px !important;

        @media (max-width: 768px) {
            height: 32px;
        }
    }

    img {
        left: 2px;
        top: 5px;
        max-width: 30px;
        filter: brightness(4);

        @media (max-width: 768px) {
            top: 2px;
        }
    }
}

.tabs-grid-image {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    overflow: auto;

    .tabs-image {
        max-width: 120px;
        height: 100px;
        width: 100%;

        @media (max-width: 768px) {
            height: 70px;
        }

        img {
            width: 100%;
            object-fit: contain;
            height: -webkit-fill-available;
        }
    }
}

.add_navbar-fs {
    @media (max-width: 768px) {
        font-size: 13px;
    }
}

.add_navbar-fs.active {
    @media (max-width: 768px) {
        font-size: 13px;
    }
}

.tabs-conatainer {
    min-width: 290px;
    max-width: 290px;
    animation: sidebar_animation ease-in-out 0.6s;
    transform: translateX(0px);
    height: calc(100vh - 56px);
    overflow-y: auto;
    position: absolute;
    left: 0px;
    z-index: 50;

    @media (max-width: 768px) {
        min-width: 220px;
        max-width: 220px;
        height: calc(100vh - 65px);
    }

    @media (max-width: 524px) {
        height: calc(100vh - 88px);
    }

    @keyframes sidebar_animation {
        0% {
            transform: translateX(-200px);
        }

        100% {
            transform: translateX(0px);
        }
    }
}

.tabs-right-conatainer {
    min-width: 290px;
    max-width: 290px;
    height: calc(100vh - 56px);
    overflow-y: auto;
    position: absolute;
    right: 0px;
    z-index: 50;
    transition: all ease-in-out 0.6s;

    @media (max-width: 768px) {
        min-width: 220px;
        max-width: 220px;
        height: calc(100vh - 65px);
    }

    @media (max-width: 524px) {
        height: calc(100vh - 88px);
    }

    .form-check {
        .label {
            font-size: 14px !important;

            @media (max-width: 768px) {
                margin-left: 3px;
            }
        }

        .form-check-input:checked {
            background-color: #000000 !important;
            border-color: #000000 !important;
        }
    }

    .replace-img-container {
        height: 180px;

        @media (max-width: 768px) {
            height: 140px;
        }

        .image {
            height: 100%;
            width: 100%;
        }

        .replace-btn {
            position: absolute;
            height: 28px;
            max-width: fit-content;
            width: 100%;
            font-weight: 600;

            img {
                width: 18px;
            }

            span {
                font-size: 12px;
                font-weight: 600;
            }
        }
    }

    .accordion {
        border-bottom: 1px solid #f6f6f6 !important;
        padding-bottom: 20px;
        border-radius: 0px !important;

        .accordion-button:not(.collapsed) {
            color: black !important;
            background-color: transparent !important;
            box-shadow: none !important;

            &::after {
                filter: brightness(0);
            }
        }

        .accordion-button {
            justify-content: start;
            gap: 15px;
            width: auto;
            font-size: 14px;
            font-weight: 600;

            &:focus {
                box-shadow: none !important;
            }

            &::after {
                background-position: center;
                width: 12px;
                height: 12px;
            }
        }

        .mySwiper {
            position: relative;

            .swipper-container {
                height: 55px;
                width: 55px;
                position: relative;
                margin-top: 10px;

                @media (max-width: 768px) {
                    height: 45px;
                    width: 45px;
                }

                .swipper-btn {
                    position: absolute;
                    top: -8px;
                    right: 0px;
                }
            }

            .label {
                font-size: 10px;
                font-weight: 700;
            }
        }

        .swiper-button-next {
            right: -2px !important;
            background-image: url("../../assets/newicons/swipper-btn.svg");

            @media (max-width: 768px) {
                background-size: 40px;
            }

            &::after {
                color: transparent;
            }
        }

        .swiper-button-disabled {
            display: none !important;
        }

        .swiper-button-prev {
            left: -2px !important;
            background-image: url("../../assets/newicons/swipper-btn.svg");
            height: 42px !important;
            rotate: 180deg;

            @media (max-width: 768px) {
                background-size: 40px;
            }

            &::after {
                color: transparent;
            }
        }
    }

    .appearance-content {
        border-bottom: 1px solid #f6f6f6;
        padding-bottom: 25px;

        @media (max-width: 768px) {
            padding-bottom: 20px;
        }

        .opacity {
            position: absolute;
            top: 6px;
            left: 8px;
        }

        .percentage {
            position: absolute;
            top: 6px;
            right: 8px;
        }

        .roundedCorner {
            position: absolute;
            top: 6px;
            left: 8px;
        }

        input {
            border: 1px solid #cccccc;
            border-radius: 8px;
            height: 32px;
            font-size: 14px;
            color: #000000;

            &:focus {
                border: 1px solid #000;
            }

            &::placeholder {
                color: #000000 !important;
            }
        }

        select {
            border: 1px solid #cccccc;
            border-radius: 8px;
            height: 32px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            cursor: pointer;

            &:focus {
                box-shadow: none !important;
            }
        }
    }
}

.add-text-button {
    border: 2px solid #282829;
    font-size: 16px;
    padding: 6px 10px;

    @media (max-width: 768px) {
        border: 1px solid #282829;
        font-size: 14px;
        padding: 3px 9px;
    }
}

.text-conatiner-box {
    background: #d9d9d9;
    height: 139px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        height: 110px;
    }

    h4 {
        color: black;
        font-size: 48px;
        font-weight: 600;

        @media (max-width: 768px) {
            font-size: 32px;
        }
    }

    p {
        font-size: 24px;
        color: #575757;

        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
}

.main-image-editor {
    background-image: url("../../assets/newimages/editor-bg.png");

    .image-editor {
        height: calc(100vh - 56px);
        max-width: 42vw;

        @media (max-width: 1400px) {
            max-width: 48vw;
        }

        @media (max-width: 1024px) {
            max-width: 58vw;
        }

        @media (max-width: 576px) {
            max-width: 75vw;
        }

        @media (max-width: 524px) {
            height: calc(100vh - 88px);
        }

        @media (max-width: 768px) {
            height: calc(100vh - 65px);
        }

        .editor-image {
            background-color: white;
            height: 80%;
            width: 80%;

            @media (max-width: 1400px) {
                height: 70%;
                width: 70%;
            }

            @media (max-width: 1024px) {
                height: 60%;
                width: 60%;
            }
        }
    }
}

.konva-text-input {
    position: absolute;
    border: 1px solid #ddd;
    padding: 4px;
    font-size: 24px;
    font-family: Arial, sans-serif;
}

.right-side-modal {
    background: white;
    position: absolute;
    right: 0;
    height: 100%;
}

.big-search-input {
    input {
        height: 56px;
        border: 1px solid black;
        padding-right: 60px;
    }

    img {
        position: absolute;
        right: 10px;
        top: 9px;
    }
}

.gnerate-textarea {
    textarea {
        border: 1px solid #cccccc;
        font-size: 14px;
        color: #000000;
        border-radius: 10px;
        padding: 16px;

        &::placeholder {
            color: #666666;
        }
    }
}

.choose-image-container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 12px;

    .choose-image {
        max-width: 73px;
        height: 62px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;

        img {
            width: 100%;
            height: auto;
        }

        &:first-child {
            border: 3px solid black;
        }
    }

    span {
        font-size: 10px;
        font-weight: 600;
        text-align: center;
    }
}

.casual-image-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;

    .casual-image {
        max-width: 118px;
        height: 118px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        overflow: hidden;

        @media (max-width: 768px) {
            height: 80px;
        }

        .image {
            width: 100%;
            object-fit: contain;
            height: -webkit-fill-available;
        }

        .heart-icon {
            position: absolute;
            right: 10px;
            top: 10px;
        }

        &:first-child {
            border: 3px solid black;
        }
    }
}

.button-active {
    left: 310px !important;

    @media (max-width: 768px) {
        left: 240px !important;
    }

    @media (max-width: 576px) {
        left: 20px !important;
    }
}

.images-zoom-button {
    box-shadow: 0px 1px 4px 0px #0000003d;
    border: 1px solid #0000001e;
    height: 46px;
    border-radius: 8px;
    position: absolute;
    left: 20px;
    bottom: 20px;

    @media (max-width: 768px) {
        height: 40px;
        font-size: 14px;
    }

    .button-wrapper {
        button {
            img {
                width: 24px;

                @media (max-width: 768px) {
                    width: 18px;
                }
            }
        }
    }
}

.version-image-active {
    right: 310px !important;

    @media (max-width: 768px) {
        right: 230px !important;
    }
}

.text-options-modal {
    box-shadow: 0px 1px 4px 0px #0000003d;
    border: 1px solid #0000001e;
    padding: 20px;
    border-radius: 8px;
    z-index: 1000;
    max-width: 450px;

    @media (max-width: 768px) {
        padding: 5px;
        max-width: 250px;
    }

    textarea {
        border-radius: 8px;
        border: 1px solid #cccccc;
        border-radius: 8px;
        padding: 15px;
        padding-bottom: 50px;

        @media (max-width: 768px) {
            padding-bottom: 40px;
        }

        &::placeholder {
            color: #666666;
        }
    }

    .text-options-wrapper {
        position: absolute;
        bottom: 9px;
        left: 3px;
        right: 8px;
        background: white;
        padding: 6px;
        border-radius: 8px;

        .text-options-btn {
            background-color: #e6e6e6;
            height: 32px;
            font-size: 14px;
            font-weight: 600;
            border-radius: 8px;
            border: none;
            padding: 0 14px;

            @media (max-width: 768px) {
                height: 27px;
                font-size: 10px;
                padding: 0 5px;
            }
        }
    }
}

.version-image-container {
    position: absolute;
    right: 10px;
    top: 10px;
    max-height: 500px;
    overflow-y: auto;
    z-index: 10;

    .version-image {
        border-radius: 10px;
        border: 2px solid rgba(255, 255, 255, 1);
        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1200px) {
            height: 50px;
            width: 50px;
        }

        @media (max-width: 576px) {
            height: 40px;
            width: 40px;
        }

        img {
            width: 100%;
            border-radius: 10px;
        }
    }
}

.text-rightclick-popup {
    box-shadow: 7px 24px 51px #0000001f;
    border: 1px solid #0000001e;
    z-index: 1000;
    max-width: 200px;
    width: 100%;
    border-radius: 4px;

    .popup-header,
    .popup-footer {
        padding: 8px 10px;
        border-bottom: 1px solid #d9d9d9;
    }

    .popup-body {
        padding: 8px 10px;
        border-bottom: 1px solid #d9d9d9;
    }

    span {
        font-size: 12px;
        font-weight: 600;
        font-family: "Noto Sans";
    }

    p {
        font-size: 12px;
        font-weight: 500;
        font-family: "Noto Sans";
        color: #808080;
    }
}

.export-modal {
    max-width: 500px;
    height: 500px;
    position: absolute;
    top: 61px;
    right: 10px;
    border-radius: 16px 0px 0px 0px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 1px 4px 0px #0000003d;
    border: 1px solid #0000001e;
    border-radius: 16px;
    z-index: 999;

    @media (max-width: 992px) {
        max-width: 400px;
        height: 400px;
    }

    @media (max-width: 576px) {
        max-width: 350px;
        height: 380px;
    }

    @media (max-width: 525px) {
        top: 90px;
        left: 5px;
    }

    .export-modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        height: 46px;

        @media (max-width: 992px) {
            height: 40px;
        }

        @media (max-width: 576px) {
            padding: 0px 10px;
        }

        h4 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 0px;

            @media (max-width: 576px) {
                font-size: 14px;
            }
        }
    }

    .export-modal-body {
        padding: 10px 20px;

        @media (max-width: 576px) {
            padding: 5px 10px;
        }

        .select {
            font-size: 16px;
            font-weight: 700;

            @media (max-width: 576px) {
                font-size: 14px;
            }
        }

        .export-image-container {
            margin-top: 12px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            @media (max-width: 992px) {
                gap: 5px;
                margin-top: 6px;
            }

            .export-images {
                height: 130px;
                width: 130px;

                @media (max-width: 992px) {
                    height: 90px;
                    width: 90px;
                    border-radius: 5px !important;
                }
            }

            .image-checkbox {
                @media (max-width: 992px) {
                    width: 16px !important;
                    height: 16px !important;
                }

                &:checked {
                    @media (max-width: 992px) {
                        background-size: cover;
                    }
                }
            }
        }

        .image-select {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            gap: 10px;

            @media (max-width: 992px) {
                gap: 5px;
                margin-top: 10px;
            }

            label {
                font-size: 16px;
                color: #000;
                font-weight: 700;
                margin-left: 0px;

                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }

            select {
                max-width: 120px;
                height: 42px;
                border: 2px solid #000;
                border-radius: 5px;
                font-size: 16px;
                padding: 0 10px;
                font-weight: 700;
                appearance: none;

                @media (max-width: 992px) {
                    height: 36px;
                }

                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }
        }

        .image-range {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            gap: 10px;

            @media (max-width: 992px) {
                gap: 5px;
                margin-top: 10px;
            }

            label {
                font-size: 16px;
                color: #000;
                font-weight: 700;
                margin-left: 0px;

                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }

            button {
                border: 1px solid #cccccc;
                width: 76px;
                height: 42px;
                border-radius: 8px;
                background-color: transparent;
                font-size: 14px;
            }

            input {
                accent-color: #000;
                width: 100%;
                height: 4px;
                cursor: pointer;
            }
        }

        .black-gradiant-btn {
            height: 40px !important;
            width: auto !important;

            &:disabled {
                border: 2px solid #e6e6e6 !important;
                color: #656565 !important;
                background: #f6f6f6 !important;
            }
        }
    }
}

.selected-template {
    border: 2px solid #000;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    transition: border 0.3s ease;
}

.template-image {
    cursor: pointer;
}

.add-konva-btn {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active {
    background-color: white;
    color: #212529 !important;
    border-radius: 4px;
}