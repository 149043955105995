/* .konva-select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 2px 28px 2px 10px;
    background: #FEDD9B;
    border-radius: 13px;
} */


.konva-select div{
    background: #FEDD9B; 
    padding: 2px 28px 2px 10px;
    border-radius: 13px;
}

.konva-select div:focus{
    background: #FEDD9B; 
    padding: 2px 28px 2px 10px;
    border-radius: 13px;
}

/* Success state */
.approved {
    background: #D1F2D1;
}

/* Failed state */
.returned{
    background: #FFE0E0;
}

.draft{
    background: #E6E6E6;
}



/* .modal{
    z-index: 99999 !important;
} */