@import "./component/variables.scss";

.fs-14 {
  font-size: var(--fs-14);

  @media (max-width: 768px) {
    font-size: var(--fs-12);
  }
}

.text-dark-black {
  color: #282829;
}

.width-fit-content {
  width: fit-content;
}

.text-black {
  color: var(--text-black) !important;
}

.background-black {
  background-color: var(--text-black);
}

.placeholder-black {
  &::placeholder {
    color: var(--text-black) !important;
  }
}

.placeholder-text-gray {
  &::placeholder {
    color: var(--border-color);
  }
}

.focus-none {
  &:focus {
    outline: none;
  }
}

.placeholder-bold {
  &::placeholder {
    font-weight: 700 !important;
  }
}

.header-border-radius {
  border-radius: 16px 16px 0 0 !important;
}

.background-white {
  background-color: var(--color-white);
  border-bottom: 1px solid #dee2e6;
}

.image-brightness-05 {
  filter: brightness(0.5);
}

.image-zoom-button {
  top: 20px;
  left: 20px;

  @media (max-width: 992px) {
    max-width: fit-content;
    position: inherit !important;
  }
}

.border-radius-8 {
  border-radius: 8px;
}

.variant-image {
  max-width: calc(100vw - 500px);

  @media (max-width: 1024px) {
    max-width: calc(100vw - 400px);
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.annotate-image {
  max-width: 650px;
  height: 600px;
  width: 100%;

  @media (max-width: 1024px) {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    max-width: 400px;
  }
}

.image-max-width {
  max-width: 550px;
  min-width: 500px;

  @media (max-width: 1024px) {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    max-width: 300px;
  }
}

.padding-r-400 {
  padding-right: 450px;

  @media (min-width: 1400px) {
    padding-right: 500px;
  }

  @media (max-width: 1024px) {
    padding-right: 380px;
  }

  @media (max-width: 768px) {
    padding-right: 0px;
  }
}

.max-w-420px {
  @media (min-width: 1400px) {
    max-width: 400px;
  }

  @media (max-width: 1400px) {
    max-width: 300px;
  }

  @media (max-width: 992px) {
    max-width: 300px;
  }

  @media (max-width: 768px) {
    // max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  z-index: 2;
}

.bg-color-gray {
  background-color: var(--bg-light-gray);
}

.fs-16 {
  font-size: var(--fs-16) !important;

  @media (max-width: 768px) {
    font-size: var(--fs-14) !important;
  }
}

.abolute-md {
  @media (min-width: 768px) {
    position: absolute;
  }
}

.heigth-calc-56 {
  height: auto;

  @media (min-width: 768px) {
    height: calc(100vh - 97px);
    min-height: 450px;
  }
}

.w-20 {
  width: 20px;
  height: 20px;
}

.rounded-16 {
  border-radius: 16px;
}

.comment-button {
  min-width: 100px;
  height: 36px;
}

.icon-box-width {
  min-width: 32px;
  height: 32px;
}

.text-dark-gray {
  color: var(--border-gray);
}

.fs-12 {
  font-size: var(--fs-12);
}

.border-black {
  border: 1px solid var(--text-black);
}

.bg-color-black {
  background-color: var(--text-black);
}

.h-36 {
  height: 36px;

  @media (max-width: 768px) {
    height: 32px;
  }
}

.h-45 {
  height: 45px;
}

.border-right-dashed {
  border-right: 2px dashed var(--bg-color-gray);

  @media (max-width: 992px) {
    border: none;
  }
}

.image-versions {
  width: 100%;
  max-width: 520px;
}

.pagination-style-top {
  // .MuiPagination-ul {
  //     li {
  //         button {
  //             border: none !important;
  //         }
  //     }
  // }

  // .MuiButtonBase-root {
  //     border: none !important;
  // }
  .p-paginator {
    background-color: transparent !important;
  }
}

.annotate-border {
  border: 1px solid #b4afa8;
  border-radius: 10px;
  // overflow: auto;
}

.zoom-btn {
  height: 25px;
  width: 25px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.bg-btn-zoom {
  background-color: #d9d9d9 !important;
}

.annotations-row {
  box-shadow: 0px 0px 1px 0px #0000003d;
  box-shadow: 0px 1px 3px 0px #0000001a;
  box-shadow: 0px 1px 2px 0px #0000000f;
  border-radius: 8px;
  // position: fixed;
  margin-left: 10px;
  left: 16px;
  bottom: 20px;
  padding: 5px;
  background-color: #ececf4;

  .MuiSelect-select {
    padding: 4.5px !important;
  }
}

.comment-select {
  .MuiSelect-select {
    padding: 6.5px 30px 6.5px 15px !important;
    font-size: 14px;
    font-weight: 600;
    font-family: "Noto Sans";
  }
}

textarea {
  resize: initial;

  &::placeholder {
    color: #808080;
  }

  &:focus {
    outline: none;
  }
}

textarea.replyy__input{
  overflow-y: auto!important;
}

.annotation-big-image {
  max-width: calc(100vw - 299px);
  overflow-x: hidden;
  // padding-right: 250px;

  @media (max-width: 768px) {
    max-width: 95vw;
    // padding-right: 50px;
  }
}

// comment side panel css

.comment-options {
  .MuiButtonBase-root {
    min-width: 0px !important;
    padding: 0px !important;
    border: none !important;
    background-color: transparent !important;
  }

  .MuiPaper-root {
    max-width: 110px !important;
  }
}

.cancel_modal_footer2 {
  min-width: 0px !important;
  max-width: 80px !important;
}

.header-submit2 {
  max-width: 80px !important;
  width: 100% !important;
  font-size: 14px;
  padding: 7px 14px 7px 16px !important;
}

.sidebar-comment {
  position: relative;

  @media (max-width:992px) {
    position: absolute;
    right: 0px;
  }
}