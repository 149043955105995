$extra-blue-dark: #141027;
$color-gray: #605e67;
$border-purple: #6257ff;
$border-light-purple: #c0bcff;
$input-switch-bg: #efebff;
$text-gray: #bcbbc0;
$text-white-gray: #acabae;
$text-light-purple: #9a72ff;
$border-blue: #3182ce;
$bg-light-blue: #579ec8;
$border-gray: #707070;

