@import './component/variables.scss';

.setting_th {
    font-size: var(--fs-14);
    font-weight: 600;
    line-height: 20px;
    color: #282829;
    white-space: nowrap;
    vertical-align: middle;
}

.setting_td {
    font-size: var(--fs-16);
    font-weight: 400;
    line-height: 24px;
    color: #171923;
    white-space: nowrap;
    vertical-align: middle;
}

.assign_role {
    font-size: var(--fs-16);
    font-weight: 600;
    line-height: 24px;
    color: #171923;
    border-bottom: 1px solid #171923;
    vertical-align: middle;
}

.role_title_name {
    font-size: var(--fs-16);
    font-weight: 400;
    line-height: 24px;
    color: #171923;
}

.role_title {
    font-size: var(--fs-14);
    font-weight: 600;
    line-height: 20px;
    color: var(--text-black);
}

.edit-default-role {
    font-size: var(--fs-20);
    font-weight: 700;
    line-height: 28px;
    color: var(--text-black);
}

.invite_user_dropdown {
    position: absolute;
    top: 45px;
    right: 20px;
}

.no_setting_img {
    background-color: var(--bg-color-gray);
    border-radius: 16px;
    width: 32px;
    height: 32px;
}

.setting_pending_desh {
    font-size: var(--fs-16);
    line-height: 24PX;
    font-weight: 400;
    color: var(--bg-color-gray);
}

.setting_pending {
    background-color: var(--bg-color-gray);
    padding: 2px 8px;
    border-radius: 10px;
    font-size: var(--fs-12);
    line-height: 16PX;
    font-weight: 600;
    color: #282829;
}