// .swal2-icon {
//     font-size: 12px !important;
//     display: none !important;
//     /* height: 50px !important;
//       width: 50px !important; */
// }

// .swal2-title {
//     font-size: 25px;
//     padding: 5px 0px 0px 0px !important;
//     color: white !important;
// }

// .swal2-styled {
//     padding: 4px 12px 4px 12px !important;
// }

// .swal2-container {
//     min-width: 25% !important;
// }

// .swal2-popup {
//     background-color: gray !important;
//     height: 50px !important;
//     display: flex !important;
//     align-items: center !important;
// }

.swal2-icon {
    font-size: 10px !important;
    /* height: 50px !important;
      width: 50px !important; */
}

.swal2-title {
    font-size: 25px;
    padding: 5px 0px 0px 0px !important;
}

.swal2-styled {
    padding: 4px 12px 4px 12px !important;
}

.swal2-container {
    min-width: 25% !important;
}