@import "../../assets/scss/variable.scss";

.border-dashed-purple {
  border: 1px dashed $border-purple;
}
.text-light-purple {
  color: $text-light-purple;
}
.fs-20 {
  font-size: 20px;
  @media (max-width: 992px) {
    font-size: 18px;
  }
  @media (max-width: 576px) {
    font-size: 15px;
  }
}

.p-fileupload-content{
  & > div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.p-fileupload-row{
  width: fit-content;
  & > div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }


}
.cancle-button {
  height: 64px;
  width: 180px;
  @media (max-width: 992px) {
    height: 50px;
    width: 160px;
  }
  @media (max-width: 576px) {
    height: 40px;
    width: 120px;
  }
}
.setting {
  .dropdown-toggle {
    position: relative;
    &::after {
      color: $extra-blue-dark;
      position: absolute;
      right: 18px;
      top: 40%;
      @media (max-width: 576px) {
      }
    }
  }
}
.form-switch {
  padding-left: 0px !important;
  .form-check-input {
    background-image: url("../../assets/icons/switchRoundWhite.svg") !important;
    height: 22px !important;
    width: 45px !important;
    margin: 0px;
    background-color: $border-light-purple;
    border: 2px solid $border-light-purple !important;
    &:focus {
      box-shadow: none !important;
    }
    &:checked {
      background-color: $text-light-purple;
      border: 1px solid $text-light-purple !important;
    }
  }
}

.spinner {
  width: 25px;
  height: 25px;
  display: grid;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side, #0000 40%, #848484 41%);
  background: linear-gradient(
        0deg,
        rgba(132, 132, 132, 0.5) 50%,
        rgba(132, 132, 132, 1) 0
      )
      center/3.2px 100%,
    linear-gradient(
        90deg,
        rgba(132, 132, 132, 0.25) 50%,
        rgba(132, 132, 132, 0.75) 0
      )
      center/100% 3.2px;
  background-repeat: no-repeat;
  animation: spinner-d3o0rx 1s infinite steps(12);
}
.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.spinner::after {
  opacity: 0.83;
  transform: rotate(60deg);
}
@keyframes spinner-d3o0rx {
  100% {
    transform: rotate(1turn);
  }
}
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loader {
  display: inline-flex;
  justify-content: space-between;
  width: 18px;
  margin-left: 2px;
  vertical-align: bottom;
  margin-bottom: 7px;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: black;
  animation: blink 1.4s infinite both;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes blink {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

// new upload
.radio-container {
  display: flex;
  align-items: center;
}
.radio-container label {
  margin-right: 15px;
  display: flex;
  margin-left: 0px !important;
  align-items: center;
}
.radio-container input[type="radio"] {
  margin-right: 5px;
}
// new upload end
.category-text {
  color: #4b4b4b;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.1em;
  text-align: left;
}
.border-grey {
  border: 1px dotted #b1b1b1;
}
.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.filter-tagss {
  border: 1px solid #707070;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 500;
  background-color: transparent;
  margin-bottom: 5px;
}

.max-w-200 {
  max-width: 200px;
}

.upload-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
  color: #4b4b4b;
}
