.anotation {
    justify-content: center;

    .exciladraw-fullscreen-btn {
        background-color: rgb(236 236 244);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--border-radius-lg);

        img {
            width: 25px;
        }
    }

    // .exciladraw dropdown-menu-item dropdown-menu-item-base{
    //     display: none !important;
    // }
    .dropdown-menu {
        border-radius: 5px;

        // z-index: 1;
        background-color: #ffffff;
        border: 1px solid #3182ce;
        margin-top: 20px !important;
        padding: 0;
        overflow: hidden;
        display: block;

        &-item {
            height: 35px;
            display: none !important;
        }

    }

    label.sidebar-trigger__label-element {
        // visibility: hidden;
        display: none;
    }

    .Stack.Stack_horizontal {
        .ToolIcon.ToolIcon__lock.ToolIcon_size_medium {
            display: none;
        }

        label[title="Insert image — 9"] {
            display: none;
        }

        button[title="More tools"] {
            display: none;
        }
    }

    .dropdown-menu-button {
        display: none;
    }

    // .dropdown-menu button[title="Find on canvas"] {
    //     display: none !important;
    // }

    // .dropdown-menu button[title="Help"] {
    //     display: none !important;
    // }

    .dropdown-menu-group {
        display: none !important;
    }

    .dropdown-menu-container div {
        // height: 0px;
        background-color: #fff !important;
    }


    .menu-icon {
        cursor: pointer;
        width: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 20px;
        margin: 10px;
        z-index: 100;
    }

    .bar {
        height: 4px;
        width: 100%;
        background-color: #000;
        /* Use the custom property */
        border-radius: 2px;
        transition: 0.3s;
    }


    .side-menu {
        // position: fixed;
        // top: 0;
        // left: -300px;
        width: 100%;
        height: 200px;
        background-color: #f8f8f8;
        color: #333;
        transition: 0.3s;
        padding: 30px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        border-radius: 0 10px 10px 0;
        z-index: 200;
    }

    .side-menu.active {
        left: 0;
    }

    .side-menu ul {
        list-style: none;
        padding: 0;
    }

    .side-menu ul li {
        margin: 20px 0;
    }

    .side-menu ul li img {
        border-radius: 8px;
        transition: transform 0.3s;
    }

    .side-menu ul li img:hover {
        transform: scale(1.05);
    }

    .side-menu ul li a {
        color: #333;
        text-decoration: none;
        font-size: 18px;
        transition: color 0.3s;
    }

    .side-menu ul li a:hover {
        color: #ffa500;
        text-decoration: underline;
    }

    .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 30px;
        cursor: pointer;
        color: #333;
        transition: color 0.3s;
    }

    .close-icon:hover {
        color: #000;
    }

    .excalidraw {
        z-index: 1;
    }

    .side-menu .image-grid {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        display: flex;
        overflow: auto;
    }

    .add-new-image {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
        cursor: pointer;
        border: 2px solid #333;
        padding: 20px;
        border-radius: 8px;
        background-color: #f8f8f8;
        color: #333;
        transition: background-color 0.3s, transform 0.3s;
        grid-column: span 2;
    }

    .add-new-image:hover {
        background-color: #e0e0e0;
        transform: scale(1.05);
    }




    .menu-title {
        font-size: 24px;
        /* Size of the title */
        font-weight: bold;
        /* Bold text for emphasis */
        margin-bottom: 20px;
        /* Space between the title and the image grid */
        color: #333;
        /* Dark color for contrast */
    }


    /* Modal styles */
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .modal-content1 {
        position: relative;
        background: white;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 30vw;
        /* Adjust width as needed */
        max-height: 80vh;
        /* Adjust height as needed */
        overflow: auto;
    }

    .modal-image {
        width: 100%;
        height: auto;
        max-width: 600px;
        max-height: 50vh;
        padding: 25px;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 30px;
        cursor: pointer;
        color: black;
    }

    .modify-button {
        margin-top: 20px;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        background: linear-gradient(135deg, #1976d2, #6965db);
        /* Green background */
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
    }

    .border-image {
        border: 2px solid black;
        /* Black border for selected image */
    }

    .modify-button:hover {
        background-color: #218838;
        /* Darker green on hover */
    }

    .new-button {
        margin-left: 10px;
        padding: 0;
        font-size: 60px;
        font-weight: bold;
        background-color: #007bff;
        /* Default button color */
        color: white;
        border: 2px solid transparent;
        /* Default border */
        border-radius: 5px;
        cursor: pointer;
        outline: none;
    }

    /* Style when New button is selected */
    .new-button.selected {
        border: 2px solid black;
        /* Black border when selected */
    }

    // .excalidraw .App-menu_bottom{
    //     flex-wrap: wrap;
    // }
    .reference-list {
        position: absolute;
        bottom: 34px;
        margin: 10px 0;
        overflow: auto;
        width: 100%;
        left: 10px;
        padding: 10px 0;
        padding-right: 50px;

        .reference-item {
            position: relative;

            .reference-close {
                // width: fit-content;
                border-radius: 50%;
                width: 17px;
                height: 17px;
                position: absolute;
                top: 3px;
                right: 0;
                cursor: pointer;
                background: rgb(255, 255, 255);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                img {
                    width: 15px;
                    height: 15px;
                }
            }

            &:hover {
                transform: scale(1.40);
            }
        }

    }
}