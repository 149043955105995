@import './component/variables.scss';

.multiselect-user .MuiButtonBase-root {
    background-color: #E6E6E6 !important;
    border: none !important;
    border-radius: 18px !important;
    height: 24px !important;

    &:last-child {
        background-color: transparent !important;

    }
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    border: none !important;
}

.multiselect-user {
    .css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root {
        padding: 0px 9px !important;
    }

    .css-2dvya8 .MuiAutocomplete-inputRoot {
        padding: 0 9px !important;
    }

}


.multiselect-user .MuiAutocomplete-endAdornment {
    // display: none;
}

.multiselect-user .MuiButtonBase-root .MuiIconButton-root .MuiIconButton-sizeMedium .MuiAutocomplete-clearIndicator .css-edpqz1 {
    display: none !important;
    visibility: hidden !important;
}

.multiselect-user .MuiButtonBase-root .MuiIconButton-root .MuiIconButton-sizeMedium .MuiAutocomplete-clearIndicator .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
    display: none !important;
    visibility: hidden !important;
}

.tabel-fild-title {
    font-family: "Noto Sans", sans-serif;
    font-size: var(--fs-14);
    line-height: 20px;
    font-weight: 600;
    border-bottom: 2px solid #CCCCCC !important;
    position: sticky !important;
    white-space: nowrap;
}

.sticky_top {
    background-color: #f8f8f8;
    position: sticky;
    top: 0;
    z-index: 1020;
    border-bottom: 2px solid #CCCCCC !important;
}

.tabel-fild {
    font-family: "Noto Sans", sans-serif;
    font-size: var(--fs-14);
    line-height: 20px;
    font-weight: 400;
    vertical-align: middle;
    white-space: nowrap;
}

.tabel-fild {
    font-family: "Noto Sans", sans-serif;
    font-size: var(--fs-14);
    line-height: 20px;
    font-weight: 600;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project_name {
    font-family: "Noto Sans", sans-serif;
    font-size: var(--fs-14);
    line-height: 20px;
    font-weight: 600;
    color: var(--text-black);
    // padding: 4px;
    white-space: nowrap;
}

.project_des {
    font-family: "Noto Sans", sans-serif;
    font-size: var(--fs-14);
    line-height: 20px;
    font-weight: 400;
    color: var(--text-black);
    // padding: 4px;
    white-space: nowrap;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search_width {
    width: 100%;

    @media (min-width: 1024px) {
        width: 75% !important;
    }

    @media (min-width: 768px) {
        width: 75% !important;
    }

    input {
        height: 56px;

        @media (max-width:768px) {
            height: 40px;
        }
    }

    img {
        position: absolute;
        top: 8px;
        right: 10px;

        @media (max-width:768px) {
            max-width: 30px;
            top: 5px;
        }
    }
}

.tabel-fild span {
    font-family: "Noto Sans", sans-serif;
    font-size: var(--fs-12);
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 2px 8px;
    border-radius: 10px;
}

.select_channel {
    font-size: var(--fs-16);
    line-height: 20px;
    font-weight: 400;
}

.from-input {
    border: 1px solid var(--border-color) !important;
    border-radius: 8px !important;
    min-height: 40px;
    font-size: var(--fs-16);
    line-height: 24px;
    font-weight: 400;
    font-family: "Noto Sans", sans-serif;
}

.from-input-link{
    border: 1px solid var(--border-color) !important;
    border-radius: 8px !important;
    min-height: 40px;
    font-size: var(--fs-16);
    line-height: 24px;
    font-weight: 400;
    font-family: "Noto Sans", sans-serif;
    // min-width: -webkit-fill-available;
    margin-top: 10px;
    flex: 1;
    box-sizing: border-box;
}

.from-input2 {
    border: 1px solid var(--border-color) !important;
    border-radius: 8px !important;
    // min-height: 40px;
    font-size: var(--fs-16);
    line-height: 24px;
    font-weight: 400;
    font-family: "Noto Sans", sans-serif;
}

::placeholder {
    font-size: var(--fs-14);
    font-weight: 400;
    line-height: 20px;
    font-family: "Noto Sans", sans-serif;
    color: #808080 !important;
}

.form-control:focus,
.btn:focus {
    box-shadow: none !important;
}

/* Remove autofill background color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #000 !important;
    caret-color: auto !important;
    background-color: var(--color-white) !important;
}

.tabel_border_bottam .table>:not(:last-child)>:last-child>* {
    border-bottom: none;
}

.project-form-error {
    font-size: var(--fs-12);
    color: red;
    margin-bottom: 5px;
}

.text-green {
    color: green !important;
}


/* From Uiverse.io by abrahamcalsin */
.dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #183153;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
}

.dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {

    0%,
    100% {
        transform: scale(0);
        opacity: 0.5;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }
}

.multiselect-user .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiPopover-paper .MuiMenu-paper .MuiMenu-paper .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    max-width: 466px !important;
    min-width: 466px !important;
    max-height: 466px !important;
    min-height: 466px !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.input-date-icon {
    position: absolute;
    right: 10px;
    top: 11px;
    cursor: pointer;
}

.date-input {
    .p-inputswitch {
        height: 21px !important;
        width: 40px !important;
    }

    .p-inputswitch.p-highlight .p-inputswitch-slider {
        background-color: #08711D !important;
    }

    .p-inputswitch.p-highlight .p-inputswitch-slider:before {
        background-color: white !important;
        left: 0px !important;
        top: 64% !important;
    }

    .p-inputswitch .p-inputswitch-slider {
        background-color: transparent !important;
        border: 1px solid #B3B3B3 !important;
    }

    .p-inputswitch .p-inputswitch-slider:before {
        background-color: #B3B3B3;
        left: 2px !important;
        height: 15px !important;
        width: 15px !important;
        top: 63% !important;
    }

    .p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:hover).p-highlight .p-inputswitch-slider {
        background: #08711D;
    }

}

.check-popover {
    background-color: red;
    padding: 10px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.rounded-25 {
    border-radius: 25px !important;
}

.custom-placeholder::placeholder {
    font-size: 14px;
    /* Adjust font size */
    color: #007bff;
    /* Adjust font color */
    font-weight: bold;
    /* Adjust font weight */
}

.swich-popup {
    position: absolute;
    left: -520px;
    bottom: 150px;
    background-color: white;
    max-width: 480px;

    @media (max-width:1560px) {
        max-width: 380px;
        left: -420px;
    }

    @media (max-width:1360px) {
        max-width: 320px;
        left: -350px;
    }

    @media (max-width:1360px) {
        max-width: 260px;
        left: -280px;
    }

    @media (max-width:1060px) {
        display: none;
    }

}

.swich-popup-curve {
    position: relative;

    &::after {
        content: "";
        height: 14px;
        width: 15px;
        position: absolute;
        right: -23px;
        background-color: white;
        top: 40%;
        rotate: 134deg;
    }
}

.comment-task-sidebar {
    @media (max-width:992px) {
        position: absolute;
        max-width: 300px !important;
        right: 0px;
        // display: none;
        height: calc(100vh - 85px) !important;
        // overflow-y: auto;
    }
}

.miro-tab-button {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    border: 2px solid #000;
    padding: 3px;

    @media (max-width:576px) {
        margin: 0 0;
    }
}

.pagination-top {
    position: absolute;

    @media (max-width:1024px) {
        position: inherit;
    }
}