.tabs-conatainer {
    min-width: 290px;
    max-width: 290px;
    animation: sidebar_animation ease-in-out 0.6s;
    transform: translateX(0px);
    height: calc(100vh - 56px);
    overflow-y: auto;
    position: absolute;
    left: 0px;
    z-index: 50;

    @media (max-width: 768px) {
        min-width: 220px;
        max-width: 220px;
        height: calc(100vh - 65px);
    }

    @media (max-width: 524px) {
        height: calc(100vh - 88px);
    }

    @keyframes sidebar_animation {
        0% {
            transform: translateX(-200px);
        }

        100% {
            transform: translateX(0px);
        }
    }
}

.tabs-search {
    input {
        padding-left: 30px !important;
        height: 40px;
        border-radius: 8px !important;
        border: 1px solid #b3b3b3 !important;
        font-size: 14px !important;

        @media (max-width: 768px) {
            height: 32px;
        }
    }

    img {
        left: 2px;
        top: 5px;
        max-width: 30px;
        filter: brightness(4);

        @media (max-width: 768px) {
            top: 2px;
        }
    }
}

.tabs-grid-image {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
   

    .tabs-image {
        max-width: 120px;
        height: 100px;
        width: 100%;

        @media (max-width: 768px) {
            height: 70px;
        }

        img {
            // width: 100%;
            object-fit: contain;
            height: -webkit-fill-available;
        }
    }
}

.micro_ad {
    font-size: var(--fs-14);
    font-weight: 600;
    line-height: 20px;
    // max-width: 50px;
  
    @media (max-width:992px) {
      font-size: 12px;
    //   max-width: 50px;
    }
  }