@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.text-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 0;
  padding-bottom: 0;
  color: #2a2a2a;
  text-align: left;
}

.title {
  font-size: 90px;
  font-weight: 900;
  line-height: 0.9;
  text-align: left;
  font-family: Lato, sans-serif;
  letter-spacing: 0.01em;
  color: #112e2f;
  @media (max-width: 1290px) {
    font-size: 60px;
  }
  @media (max-width: 768px) {
    font-size: 50px;
  }
}

.subtext {
  font-size: 21px;
  font-family: Lato;
  font-weight: 500;
  text-align: left;
  color: #ada7a7;
  @media (max-width: 1290px) {
    font-size: 12px;
  }
  // @media (max-width:768px) {
  //   font-size: 50px;
  // }
}

.mico-subtext {
  font-family: Lato;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.3em;
  text-align: left;
  color: #ada7a7;
  @media (max-width: 1290px) {
    font-size: 12px;
  }
  // @media (max-width:768px) {
  //   font-size: 50px;
  // }
}

.login-btn {
  padding: 0.2vw 2vw;
  font-size: 1.5vw;
  background-color: #3182ce;
  border: none;
  border-radius: 0.5vw;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.login-btn:hover {
  background-color: #276bb5;
  color: white;
}

.dashboard-card {
  min-width: 290px;
  max-width: 300px;
  width: 100%;
  min-height: 330px;
  border: 2px solid #112e2f;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px 50px;
  box-shadow: 0px 0px 10px 0px #ffffff1a;
  cursor: pointer;
}

.dash-card {
  min-width: 290px;
  max-width: 300px;
  width: 100%;
  min-height: 330px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px 50px;
  cursor: pointer;
  border: 0;
  box-shadow: 0 0 10px 0 #0000001a;

  // Media queries for responsiveness
  @media (max-width: 600px) {
    padding: 20px 20px;
  }

  @media (max-width: 960px) {
    padding: 20px 40px;
  }
}

.dashboard-card-disabled {
  max-width: 300px;
  width: 100%;
  min-height: 330px;
  border: 2px solid #706c6c;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px 50px;
  box-shadow: 0px 0px 10px 0px #0000001a;
  opacity: 0.6;
  pointer-events: none;
}

.disabled {
  color: #cccccc !important;
}

.dashboard-title {
  color: #4b4b4b;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
}

.btn {
  display: flex;
  justify-content: center;
  background-color: #3182ce;
  color: #ffffff;
}

.disabledbtn {
  display: flex;
  justify-content: center;
  background-color: #cccccc;
  color: #ffffff;
}

.min-w-300 {
  min-width: 300px;
  max-width: 400px;
  width: 100%;
}

.height-auto {
  @media (max-width: 768px) {
    height: auto !important;
  }
}
