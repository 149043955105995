:root {

    --text-black: #000000;
    --bg-light-gray: #F6F6F6;
    --border-gray: #666666;
    --color-white: #ffffff;
    --bg-color-gray: #cccccc;
    --border-color: #B3B3B3;


    --fs-16: 16px;
    --fs-20: 20px;
    --fs-14: 14px;
    --fs-12: 12px;
    --fs-24: 24px;
}