.folder-stack {
  position: relative;
  width: 160px;
  /* Adjust based on your folder width */
  height: 160px;

  /* Adjust based on your folder height */
  @media (max-width:400px) {
    width: 110px;
    height: 110px;
  }
}

.folder-item {
  position: absolute;
  width: 100%;
  /* Adjust based on your folder width */
  height: 100%;
  left: 0;
  top: -12px;
  padding: 3px;


}

.folder-item img {
  //  width: 100%; /* Adjust based on your folder width */
  //  height: 100%;
  object-fit: cover;
  border-radius: 8px
}