@import '../component/variables.scss';

// .navbar-nav .nav-link {
//   padding-right: 2rem !important;
//   padding-left: 1rem !important;
// }
.cursor-pointer {
    cursor: pointer;
}

.border-line {
    border: 1px solid rgba(217, 217, 217, 1);
    height: 24px;
}

.header-fild-title {
    font-family: "Noto Sans", sans-serif;
    font-size: var(--fs-14);
    line-height: 20px;
    font-weight: 600;
    color: var(--text-black);
}

.dropdown-toggle::after {
    margin-left: 10px !important;
    margin-bottom: -4px !important;
}


.dropstart .dropdown-menu[data-bs-popper] {
    top: 42px;
    right: 0px;
    border-radius: 16px;
    border: none;
    max-height: 310px;
    overflow: auto;
    scrollbar-width: auto;
    z-index: 1021;
}

.navbar-toggler:focus {
    border: none !important;
    box-shadow: none !important;
}

.max-w-250 {
    max-width: 400px;
    min-width: 250px;

    @media (max-width:576px) {
        max-width: 400px;
        min-width: 150px;
    }
}

.remove_outline_header {
    outline: none !important;
    border: none !important;
    background-color: transparent;
}

.notification {
    padding: 0 0 8px 0 !important;
    max-width: 385px;
    min-width: 380px;
    width: 100%; // Add this line

    @media (max-width: 576px) {
        min-width: 300px;
    }

    @media (max-width: 400px) {
        min-width: 250px;
    }
}

.main_title_drop {
    font-family: "Noto Sans", sans-serif;
    font-size: var(--fs-16);
    line-height: 24px;
    font-weight: 600;
    color: var(--text-black);
}


.profile_tile {
    font-family: "Noto Sans", sans-serif;
    font-size: var(--fs-14);
    line-height: 20px;
    font-weight: 600;
    color: var(--text-black);
    padding: 2px 0;
    white-space: break-spaces;
}

.profile_tile_short_des {
    font-family: "Noto Sans", sans-serif;
    font-size: var(--fs-14);
    line-height: 20px;
    font-weight: 400;
    color: var(--text-black);
    padding: 2px 0;
    white-space: break-spaces;
}

.profile_time {
    font-family: "Noto Sans", sans-serif;
    font-size: var(--fs-12);
    line-height: 16px;
    font-weight: 400;
    color: var(--border-gray);
    padding: 2px 0;
}

.nofi_profile {
    width: 50px;
    height: 50px;
    background-color: #E6E6E6;
    border-radius: 50px;
}

.dropdown-item:active {
    background-color: gray;
}

.notification .dropdown-item:focus {
    outline: none;
    // box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.dropstart {
    .MuiBadge-root {
        top: 6px !important;
        right: -30px !important;

        @media (max-width:768px) {
            right: -25px !important;
        }
    }


    .css-zza0ns-MuiBadge-badge {
        padding: 3px !important;
        font-size: 12px !important;

        @media (max-width:768px) {
            padding: 0px !important;
            font-size: 0.50rem !important;

        }
    }
}

.header-selection-width {
    min-width: 127px;

    @media (max-width:576px) {
        min-width: 80px;
    }
}

.dropdown-image-width {
    @media (max-width:576px) {
        max-width: 35px !important;
    }
}

.dropdownMenuNotification-width {
    @media (max-width:576px) {
        max-width: 35px;
    }
}

.profile-manu {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    font-size: 16px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width:576px) {
        font-size: 14px;
        height: 25px;
        width: 25px;
    }
}