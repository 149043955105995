@import "./component/variables.scss";

.bg_color_gray {
  background-color: var(--bg-light-gray);
  height: 100%;
}

.header_line {
  // border: 1px solid #d9d9d9;
  // height: 16px;
}

.micro_ad {
  font-size: var(--fs-14);
  font-weight: 600;
  line-height: 20px;
  max-width: 150px;

  @media (max-width: 992px) {
    font-size: 12px;
  }
}

.active_header {
  border: 1px solid var(--border-gray);
  border-radius: 40px;
  padding: 8px 10px;
  font-size: var(--fs-14);
  font-weight: 600;
  line-height: 20px;
}

.dropdown-toggle::after {
  margin-left: 8px !important;
}

.bg-white {
  background-color: var(--color-white);
}

.bora_24 {
  border-radius: 24px;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.8s ease-in-out;
}

.keyboardArrow {
  transition: transform 0.8s ease-in-out;
}

.transition {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 1s ease-in-out, opacity 1s ease-in-out;
}

.transition.visible {
  max-height: 1000px;
  /* Adjust this to fit the maximum height of your content */
  opacity: 1;
}

.bg_white_padding {
  padding: 32px;
}

.text_formate {
  font-size: var(--fs-16);
  font-weight: 700;
  list-style: 24px;
  color: var(--text-black);
}

.add_reference {
  border: 1px dashed var(--border-color);
  border-radius: 16px;
  height: 160px;
  width: 160px;

  .Add {
    width: 50px;
  }
}

.add_reference_text {
  font-size: var(--fs-12);
  font-weight: 700;
  line-height: 16px;
}

.add_reference_img {
  background-color: var(--border-gray);
  width: 160px;
  height: 160px;
  border-radius: 16px;
}

.chat_bottom {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.chat_bottom_text {
  color: var(--color-white);
  font-size: var(--fs-14);
  line-height: 16px;
  font-weight: 700;
}

.border_bootom {
  border-bottom: 1px solid #e6e6e6;
}

.border_bootom:last-child {
  border-bottom: none !important;
}

.Add_reference_text {
  font-size: var(--fs-20);
  font-weight: 700;
  line-height: 28px;
}

.modal_lg {
  max-width: 1648px;
  max-height: 972px;
  margin: 1.75rem auto;
}

.add_navbar {
  font-size: var(--fs-16);
  font-weight: 500;
  line-height: 24px;
  padding: 8px 16px;
}

.add_navbar.active {
  font-size: var(--fs-16);
  font-weight: 700;
  line-height: 24px;
  padding: 8px 16px;
  border-bottom: 2px solid var(--text-black) !important;
}

.add_body_recent {
  font-size: var(--fs-14);
  font-weight: 700;
  line-height: 20px;
}

.search_iteam {
  padding: 2px 6px;
  border-radius: 50px;
  background-color: #e6e6e6;
  border: 1px solid #ffffff;
  margin: 10px 0;
}

.search_iteam_name {
  font-size: var(--fs-14);
  font-weight: 400;
  line-height: 20px;
  padding: 2px;
}

.reference_name {
  font-size: var(--fs-24);
  line-height: 32px;
  font-weight: 600;
  color: var(--text-black);

  @media (max-width: 768px) {
    font-size: var(--fs-20);
  }
}

.referencebtn {
  cursor: pointer;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  border-radius: 40px;
  padding: 12px 24px;
  background-color: #000;
  color: #fff;
}

.img_des {
  font-size: var(--fs-16);
  line-height: 24px;
  font-weight: 400;
  color: #282829;
  font-family: "Noto Sans";

  @media (max-width: 768px) {
    font-size: var(--fs-14);
  }
}

.project_details {
  font-size: var(--fs-16);
  line-height: 20px;
  font-weight: 700;
}

.save_btn {
  cursor: pointer;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  border-radius: 40px;
  padding: 8px 16px;
  background-color: #000;
  color: #fff;
  margin-top: 50px;
}

.col-form-label {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
}

.whait_whit_text {
  position: absolute;
  top: 10px;
  left: 10px;
}

.replace_text {
  position: absolute;
  top: 80px;
  left: 50px;
}

.delete_text {
  position: absolute;
  top: 50px;
  left: 48px;
}

.whait_whit_text_right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.whait_gray_text {
  font-size: var(--fs-12);
  line-height: 16px;
  font-weight: 600;
  background-color: #ffffff82;
  padding: 2px 8px;
  text-transform: uppercase;
  border-radius: 10px;
  color: #282829;
}

.whait_green_text {
  font-size: var(--fs-12);
  line-height: 16px;
  font-weight: 600;
  background-color: #b2dec8;
  padding: 2px 8px;
  text-transform: uppercase;
  border-radius: 10px;
  color: #046938;
}

.whait_red_text {
  font-size: var(--fs-12);
  line-height: 16px;
  font-weight: 600;
  background-color: #f6b8b0;
  padding: 2px 8px;
  text-transform: uppercase;
  border-radius: 10px;
  color: #a01200;
}

.replace {
  font-size: var(--fs-12);
  line-height: 16px;
  font-weight: 600;
  background-color: #f3dede;
  padding: 2px 8px;
  // text-transform: uppercase;
  border-radius: 10px;
  color: black;
}

.delete {
  font-size: var(--fs-12);
  line-height: 16px;
  font-weight: 600;
  background-color: #f3dede;
  padding: 2px 13px;
  // text-transform: uppercase;
  border-radius: 10px;
  color: black;
}

.request_approval_modal {
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  color: var(--text-black);
}

.submit_not_approval {
  background-color: var(--bg-color-gray);
  border: 1px solid var(--bg-color-gray);
  padding: 8px 16px;
  border-radius: 40px;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  color: var(--color-white);
}

.submit_approval {
  background-color: var(--text-black);
  border: 1px solid var(--text-black);
  padding: 8px 16px;
  border-radius: 40px;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  color: var(--color-white);
  // white-space: nowrap;
}

.cansal_submmit {
  background-color: var(--color-white);
  border: 2px solid var(--border-gray);
  padding: 8px 16px;
  border-radius: 40px;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  color: var(--text-black);
  // white-space: nowrap;
}

.upload_design {
  font-size: var(--fs-20);
  line-height: 28px;
  font-weight: 600;
}

.p-fileupload .p-fileupload-content {
  border: 1px dashed var(--border-color);
  border-radius: 8px;
  padding: 32px 0;
  margin-bottom: 20px;
}

.review_action_menu {
  top: 6px !important;
  border: none !important;
  box-shadow: 0px 0px 15px -3px #0000006e;
  padding: 5px !important;
}

.review_action_dropdown {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 400;
  color: var(--text-black);
  vertical-align: middle;
  padding: 12px 16px !important;
  border-bottom: none !important;
}

.share_client_modal {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  color: var(--text-black);
}

.share_imput {
  height: 40px;
  padding: 8px 16px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.approve_all_title {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-20);
  line-height: 28px;
  font-weight: 700;
  color: var(--text-black);
}

.approve_all_des {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-16);
  line-height: 24px;
  font-weight: 400;
  color: var(--text-black);
}

.cancel2_modal_footer {
  max-width: 119px !important;
  min-width: 119px !important;
  background-color: var(--color-white);
  padding: 10px 16px;
  border-radius: 16px;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  color: var(--text-black);
  border: none;
  border-radius: 40px;
}

.yes_modal_footer {
  max-width: 119px !important;
  min-width: 119px !important;
  background-color: var(--text-black);
  padding: 10px 16px;
  border-radius: 16px;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  color: var(--color-white);
  border: none;
  border-radius: 40px;
}

.chate_box {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 46px;
  height: 46px;
  background: linear-gradient(
    180deg,
    rgba(40, 40, 41, 1) 0%,
    rgba(102, 102, 102, 1) 100%
  );
  border-radius: 150px;
  box-shadow: 0px 10px 25px -5px rgb(3, 3, 3, 0.5);
  z-index: 99999;
}

.chatebox_open {
  max-width: 64vh;
  min-width: 64vh;
  max-height: 85vh;
  min-height: 90vh;
  background-color: var(--color-white);
  position: fixed !important;
  bottom: 60px !important;
  right: 30px;
  width: 100%;
  box-shadow: 0px 0px 25px -5px #00000052;
  border-radius: 32px;
  z-index: 999;

  @media (max-width: 600px) {
    max-width: 37vh;
    min-width: 37vh;
    max-height: 85vh;
    min-height: 85vh;
    right: 10px;
  }
}

.main_contaent {
  // max-width: 64vh;
  // min-width: 64vh;
  // max-height: 66vh;
  // min-height: 76vh;
  // overflow-y: scroll;
  // padding: 20px;

  @media (max-width: 600px) {
    // max-width: 37vh;
    // min-width: 37vh;
    // max-height: 60vh;
    // min-height: 60vh;
  }

  .comment-content {
    height: 53vh;
    overflow: auto;
  }

  .add-comment {
    background-color: rgb(255 255 255);
    position: relative !important;
  }

  .bg-color-gray {
    background-color: rgb(255 255 255);
  }
}

.chate_detail_full_des {
  margin: 20px 0;
}

.chatbox_img {
  width: 48px;
  height: 48px;
  border-radius: 46px;
  background-color: #e6e6e6;
}

.chate_short_title {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  color: var(--text-black);
}

.chate_detail_bg_right {
  background-color: var(--bg-light-gray);
  max-width: 52vh;
  min-width: 52vh;
  max-height: 18vh;
  min-height: 18vh;
  border-right: 2px solid var(--text-black);
  padding: 8px 16px;
  overflow-y: scroll;

  @media (max-width: 600px) {
    max-width: 24vh;
    min-width: 24vh;
    max-height: 22vh;
    min-height: 22vh;
  }
}

.chate_detail_bg_left {
  background-color: var(--bg-light-gray);
  max-width: 52vh;
  min-width: 52vh;
  max-height: 18vh;
  min-height: 18vh;
  border-left: 2px solid var(--text-black);
  padding: 8px 16px;
  overflow-y: scroll;

  @media (max-width: 600px) {
    max-width: 24vh;
    min-width: 24vh;
    max-height: 22vh;
    min-height: 22vh;
  }
}

.chate_des_detail {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 400;
  color: var(--text-black);
  padding: 0 0 10px 0;
}

.chate_des_img {
  background-color: var(--border-gray);
  width: 82px;
  height: 82px;
  border-radius: 16px;
}

.chate_reply {
  font-family: "Noto Sans", sans-serif;
  font-size: var(--fs-14);
  line-height: 20px;
  font-weight: 600;
  color: var(--text-black);
}

.write_end {
  text-align: end;

  @media (max-width: 600px) {
    max-width: 24vh;
  }
}

.write_start {
  text-align: start;

  @media (max-width: 600px) {
    max-width: 24vh;
  }
}

.chate_detail_bg_right_reply {
  background-color: var(--bg-color-gray);
  max-width: 40vh;
  padding: 8px 16px;
  border-radius: 16px;

  @media (max-width: 600px) {
    max-width: 24vh;
  }
}

.chate_detail_bg_left_reply {
  background-color: var(--bg-color-gray);
  max-width: 40vh;
  padding: 8px 16px;
  border-radius: 16px;

  @media (max-width: 600px) {
    max-width: 24vh;
  }
}

.multi_user .multiselect-user .MuiAutocomplete-endAdornment {
  display: none;
}

.multi_user .MuiButtonBase-root .MuiIconButton-root .MuiIconButton-sizeMedium {
  display: none !important;
  visibility: hidden !important;
}

.multiselect-user {
  .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
    margin-right: 5px;
    display: none;
  }

  .css-38raov-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    margin: 0px 2px 0 -2px !important;
  }

  .MuiSvgIcon-root
    .MuiSvgIcon-fontSizeMedium
    .MuiChip-deleteIcon
    .MuiChip-deleteIconMedium
    .MuiChip-deleteIconColorDefault
    .MuiChip-deleteIconFilledColorDefault
    .css-i4bv87-MuiSvgIcon-root {
    margin: 0px 2px 0 -2px !important;
  }
}

.approver-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 3px solid white;
  background-color: black;
  font-size: 12px;
  color: white;
  text-align: center;
  vertical-align: middle;
  margin-left: -13px;
  font-family: "Noto Sans";
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-left: 0px;
  }
}

.project-stepper {
  display: grid;
  // grid-template-columns: auto auto auto auto;
  width: 90%;
  gap: 22px;

  @media (max-width: 576px) {
    grid-template-columns: auto auto auto;
  }

  .progress {
    height: 4px !important;
    background-color: #cccccc !important;
  }
}

.gray-text-color {
  color: var(--border-color);
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.light-red-bg {
  background-color: #fce8e6;
}

.header-selector {
  .MuiInputBase-input {
    padding: 0px 32px 0px 5px;
  }

  .MuiSvgIcon-fontSizeMedium {
    color: white !important;
  }
}

.MuiPaper-root {
  max-width: 200px !important;
  width: 100%;
  margin-top: 10px;
  border-radius: 8px !important;
}

.header-submit {
  background-color: black;
  color: white;
  padding: 8px 16px 8px 16px;
  border: none !important;

  &:disabled {
    background-color: #c5c5c5;
  }

  @media (max-width: 992px) {
    font-size: 14px;
    padding: 8px 8px 8px 8px;
  }

  @media (max-width: 768px) {
    padding: 4px 8px 4px 8px;
  }
}

.image-container-ref {
  border: 2px solid transparent;
  border-radius: 20px;
  overflow: hidden;

  &:hover {
    border: 2px solid black;
    border-radius: 20px;

    .image-checkbox {
      display: flex;
    }
  }

  .image-checkbox {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    border: 2px solid #808080;
    accent-color: black;
    display: none;
    appearance: none;
    background-color: white;
    border-radius: 50%;

    &:checked {
      background-image: url("../../assets/newicons/check_circle_onImage.svg");
      background-position: center;
      border: transparent;
    }
  }

  &.selected {
    border: 2px solid black;

    .image-checkbox {
      display: flex;
    }
  }
}

.close-popup-button {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 5;
}

.reference-image {
  max-width: 560px;
  width: 100%;
  max-height: 560px;
  height: 100%;
}

.refrenece-image-width {
  width: 50%;

  @media (max-width: 992px) {
    width: 100%;
    max-height: 50vh;
    height: 100%;
  }
}

.reference-grid {
  padding: 40px;

  @media (max-width: 1200px) {
    padding: 20px;
  }

  .reference-grid-image {
    border: 1px solid #b3b3b3;
    max-width: 330px;
    max-height: 330px;

    @media (max-width: 1200px) {
      max-width: 300px;
      max-height: 300px;
    }

    @media (max-width: 768px) {
      max-width: 250px;
      max-height: 250px;
    }

    @media (max-width: 576px) {
      max-width: 200px;
      max-height: 200px;
    }
  }

  .reference-grid-image-add {
    cursor: pointer;
    border: 1px dashed #b3b3b3;
    max-width: 330px;
    max-height: 330px;
    height: 350px;

    @media (max-width: 1200px) {
      max-width: 300px;
      max-height: 300px;
    }

    @media (max-width: 768px) {
      max-width: 250px;
      max-height: 250px;
    }

    @media (max-width: 576px) {
      max-width: 200px;
      max-height: 200px;
    }
  }
}

.sidebar-open-btn {
  height: 80px;
  width: 24px;
  background-color: white;
  box-shadow: 0px 0px 1px 0px #0000003d, 0px 1px 3px 0px #0000001a,
    0px 1px 2px 0px #0000000f;
  position: fixed;
  top: 45%;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 0 0 5px;
  border: none;
  z-index: 1;
  cursor: pointer;

  span {
    border-width: 2px 2px 0 0;
    height: 10px;
    width: 10px;
    border-style: solid;
    border-color: #1c1b1f;
    rotate: 224deg;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.sidebar-close-btn {
  height: 80px;
  width: 24px;
  background-color: white;
  box-shadow: 0px 0px 1px 0px #0000003d, 0px 1px 3px 0px #0000001a,
    0px 1px 2px 0px #0000000f;
  position: absolute;
  top: 45%;
  left: -23.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 0 0 5px;
  border: none;
  cursor: pointer;

  span {
    border-width: 2px 2px 0 0;
    height: 10px;
    width: 10px;
    border-style: solid;
    border-color: #1c1b1f;
    rotate: 45deg;
  }

  @media (max-width: 768px) {
    // display: none;
  }
}

.submit-client-approval {
  background-color: #f6f6f6;
}

.submit-client-approval-textarea {
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  resize: vertical !important;
  padding: 15px;
  font-family: "Noto Sans";
  font-size: 14px;
  color: #282829 !important;

  &::placeholder {
    color: #282829 !important;
    font-family: "Noto Sans";
    font-size: 14px;
  }
}

.main-big-search {
  input {
    height: 56px;

    @media (max-width: 768px) {
      height: 38px;
    }
  }

  .position-absolute {
    right: 10px;
    top: 8px;

    @media (max-width: 768px) {
      top: 0px;
      right: 0px;
    }
  }
}

.info-error {
  &:hover {
    .info-error-hover-content {
      display: block;
    }
  }

  .info-error-hover-content {
    display: none;
    background-color: white;
    position: absolute;
    width: 340px;
    padding: 12px;
    border-radius: 8px;
    left: 0px;
    cursor: pointer;
  }
}

// image card skeleton css
.max-w-170 {
  max-width: 170px;
}

.image-card {
  max-height: 535px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0px;
  margin-top: 10px;

  .card_body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-wrap: wrap;
    padding: 0 10px;
  }
}

// css for image overlay
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 16px;
}

.grid-item {
  position: relative;
  overflow: hidden;
}

.grid-item img {
  width: 100%;
  height: 100%;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 10px;
}

.grid-item:hover .overlay {
  opacity: 1;
}

.grid-item_ref:hover .overlay {
  opacity: 1;
}

.overlay button {
  background-color: #b1a9a9;
  color: white;
  border: none;
  padding: 0.5vh 2vh;
  margin: 5px 0;
  cursor: pointer;
  border-radius: 2vh;
  font-size: 11px;
}

.tag {
  position: absolute;
  top: 11px;
  left: 9px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 12px;
}

.tag-revision {
  background-color: #ffe6e6;
  color: #990000;
}

.tag-approved {
  background-color: #e6ffe6;
  color: #006622;
}

.tag-uploaded {
  background-color: grey;
  color: white;
}

.reference-view-mode {
  .dropdown-menu-button {
    display: none !important;
  }
}

.hover-container {
  // border: 1px solid black;
  background-image: url("../../assets/newimages/editor-bg.png");
  border-radius: 10px;
  background-size: cover; // Ensures the image covers the entire container
  background-position: center; // Centers the image within the container
  width: 100%; // Adjust as needed
  height: 100%; // Adjust as needed
  padding: 20px;
  position: relative;
  opacity: 0.7;

  &:hover {
    .hover-button {
      display: block;
    }
  }

  .wireframe-transparent-layer {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 999;
    background-color: #CCFF0000;
    border-radius: 10px;
  }

  .hover-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    z-index: 1;
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 40px;
    z-index: 99999999;
  }

  .workspace-image {
    height: 240px;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    filter: blur(3px);
  }
}

.bg-gray-input {
  background-color: #cccccc;
}

.psd-link {
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.psd-link-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.psd-link-item a {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.psd-link-actions {
  display: flex;
  gap: 5%;
  align-items: center;
}

.psd-link-actions button {
  padding: 5px 10px;
}

.truncated-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: calc(100% - 150px);
}
.dotted {
  border-top: 2px dotted #999;
}

.psd-link-with-button {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.error {
  border-color: red;
}

.add-btn-link{
  padding: 8px 16px; /* Adjust button padding for better alignment */
  font-size: var(--fs-16);
  border-radius: 8px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  cursor: pointer;
  height: 40px; /* Match the input's height */
  display: flex;
  margin-bottom: -7px;
  align-items: center; /* Center button text vertically */
  justify-content: center; /* Center button text horizontally */
  white-space: nowrap; /* Prevent text wrapping */
}
