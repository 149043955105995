@import "../../../assets/scss/variable.scss";

.max-w-1260 {
  max-width: 75vw;

  @media (max-width: 650px) {
    max-width: 85vw;
  }
}

.max-w-120 {
  max-width: 120px;
}

.max-w-312 {
  max-width: 312px;
  min-width: 200px;
}

.max-h-498 {
  max-height: 498px;

  @media (max-width: 768px) {
    max-height: none !important;
  }
}

.border-dotted-bottom {
  border-bottom: 1px dotted $border-gray;
}

.min-h-150 {
  min-height: 150px;
}

.border-gray-solid {
  border: 1px solid $border-gray;
}

.max-w-170 {
  max-width: 170px;
}

.bg-sky-blue {
  background-color: #a6daff;
}

.color-light-gray {
  color: $border-gray;
}

.font-s-24 {
  font-size: var(--fs-24);

  @media (max-width: 1200px) {
    font-size: 20px;
  }

  @media (max-width: 766px) {
    font-size: var(--fs-16);
  }
}

.back-btn {
  z-index: 10;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}

.back-btn-position {
  @media (max-width: 400px) {
    position: absolute;
    left: 4px;
    top: 55px;
  }
}

.flex-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.download-content-section {
  top: 240px;

  @media (max-width:1200px) {
    max-width: 80vw !important;
  }
}