.container-main {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin: 5vh 0px;
}

.item {
    width: 160px;
    height: 160px;
}

.item img {
    width: 100%;
    height: 100%;
    display: block;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    border-bottom: 1px solid #ecedee;
}

.text-bold {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
}

.text {
    font-size: 20px;
    margin: 0;
}

.icon {
    width: 3%;
}

.addImage {
    border: 1px solid #ecedee;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

// Slider CSS

.slider-container {
    width: 100%;
    // width: 70%;
    // margin: 5vh;
    padding: 10px;
    height: 500px;
}

.nav-container {
    padding: 10px;
    display: flex;
    justify-content: center;
}

.slideBtn {
    border: none;
    margin: 1vh;
    color: #000;
    font-weight: 700;
}

.count {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 700;
}

.slides-container {
    display: flex;
    overflow: hidden; /* Prevent slides from showing outside the container */
    width: 100%;
    height: 450px;
    // margin-bottom: 5vh;
}

.slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    margin-inline: 5vh;
    min-width: 80%; /* Ensure each slide takes the full width of the container */
    box-sizing: border-box;
}
// .slider {
//     margin-bottom: 5vh;
//     height: 450px;
//     display: flex;
//     width: 100%;
//     overflow: hidden;
// }

// .slides {
//     display: flex;
//     transition: transform 0.5s ease;
//     height: 400px;
// }

// .slide {
//     width: 500px;
//     height: 100%;
//     text-align: center;
//     margin: 2vh;
// }

.slide img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: contain;
}



.grid-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 10px;
    padding: 20px;

    @media (max-width:992px) {
        width: 100%;
    }
}

.grid-wrapper img {
    border-radius: 10px;
}

.design-close-btn {
    margin-top: -20px;

    @media (max-width:630px) {
        position: absolute;
        right: 10px;
        top: 34px
    }
}

.design-flex-wrap {
    @media (max-width:630px) {
        flex-wrap: wrap;
    }
}

.design_img {
    background-color: var(--border-gray);
    width: 160px;
    height: 160px;
    border-radius: 16px;
  }